<template>
  <el-card class="sm:px-0">
    <ul v-if="inventoryList.length > 0" class="-my-20">
      <li
        v-for="(product, idx) in inventoryList"
        :key="idx"
        class="bg-white hover:bg-grey-50 py-16 px-16 sm:px-24 border-b border-transparent cursor-pointer"
        :class="{ 'border-hr': idx !== inventoryList.length - 1 }"
        @click="onRowClick(product)"
      >
        <div class="flex flex-1 items-center justify-between mb-8">
          <p class="text-green-500 font-medium truncate">{{ product.title }}</p>

          <el-status status="Open" class="normal-case whitespace-no-wrap">
            {{ product.quantity }} available
          </el-status>
        </div>

        <div class="sm:flex sm:items-center sm:justify-between">
          <p class="text-grey-400">When sold: {{ product.sold }}</p>

          <span class="inline-block text-green-500">{{ product.status }}</span>
        </div>
      </li>
    </ul>
  </el-card>
</template>

<script>
const ElCard = () => import('@/components/shared/ELCard')
const ElStatus = () => import('@/components/shared/ELStatus')

export default {
  name: 'DashboardList',

  components: { ElCard, ElStatus },

  props: {
    isRowClick: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      inventoryList: [
        {
          title: 'Flora Danica Rødkløver sengesæt fra Södahl (SKU: 1000135)',
          sold: 'Stop selling',
          status: 'Manage',
          quantity: 21
        },
        {
          title: 'Flora Danica Rødkløver sengesæt fra Södahl (SKU: 1000135)',
          sold: 'Stop selling',
          status: 'Manage',
          quantity: 21
        },
        {
          title: 'Flora Danica Rødkløver sengesæt fra Södahl (SKU: 1000135)',
          sold: 'Stop selling',
          status: 'Manage',
          quantity: 21
        },
        {
          title: 'Flora Danica Rødkløver sengesæt fra Södahl (SKU: 1000135)',
          sold: 'Stop selling',
          status: 'Manage',
          quantity: 21
        },
        {
          title: 'Flora Danica Rødkløver sengesæt fra Södahl (SKU: 1000135)',
          sold: 'Stop selling',
          status: 'Manage',
          quantity: 21
        },
        {
          title: 'Flora Danica Rødkløver sengesæt fra Södahl (SKU: 1000135)',
          sold: 'Stop selling',
          status: 'Manage',
          quantity: 21
        }
      ]
    }
  },

  methods: {
    onRowClick (product) {}
  }
}
</script>
