<template>
  <div>
    <div class="flex flex-col items-start sm:flex-row sm:items-center justify-between mb-20">
      <h1 class="font-medium text-lg whitespace-no-wrap">Inventory overview</h1>

      <el-button tag="button" type="primary" class="mt-12 sm:mt-0 sm:ml-12">Contact Avisfordele</el-button>
    </div>
    <inventory-list />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ElButton from '@/components/shared/ELButton'
import InventoryList from '@/components/inventory/InventoryList'

export default {
  name: 'InventoryPage',

  components: { ElButton, InventoryList },

  created () {
    this.loading(false)
  },

  methods: {
    ...mapActions(['loading'])
  }
}
</script>
